import base from './base'

export default {
  ...base,
  colors: {
    // ...base.colors,
    // shadow1: '#000',
    // text1: '#FFFFFF',
    // text2: '#C3C5CB',
    // text3: '#6C7284',
    // text4: '#565A69',

    // backgrounds / greys
    // bg0: '#1B0C01',
    // bg1: '#090400',
    // bg2: '#222528',
    // bg3: '#24252A',
    // bg4: '#565A69',
    // bg5: '#6C7284',

    //specialty colors
    // modalBG: 'rgba(0,0,0,.425)',
    // advancedBG: 'rgba(0,0,0,0.1)',

    //primary colors
    // primary1: '#FFB047',
    // primary4: '#e5a71f',
    // primary5: '#FFB047',

    // color text
    // primaryText1: '#FFFFFF',
    // buttonText: '#ffffff',

    // secondary colors
    // secondary1: '#FFB047'
    ...base.colors,
    shadow1: '#2F80ED',
    text1: '#121211',
    text2: '#494951',
    text3: '#888D9B',
    text4: '#C3C5CB',

    // backgrounds / greys
    bg0: '#f1f2f4',
    bg1: '#FFFDFA',
    bg2: '#eeeeee',
    bg3: '#e5e6ec',
    bg4: '#CED0D9',
    bg5: '#FFB047',
    modalBG: 'rgba(0,0,0,0.3)',
    advancedBG: 'rgba(255,255,255,0.8)',

    //primary colors
    primary1: '#FFB047',
    primary4: '#e5a71f',
    primary5: '#FFB047',

    // color text
    primaryText1: '#FFFFFF',
    buttonText: '#ffffff',

    // secondary colors
    secondary1: '#13aab5'
  }
}
